import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const UMiamiLandingPage = () => (
  <PhysicianLandingPage
    physician="University of Miami"
    institution="University of Miami"
    referralCode="UMIAMI"
    physicianURL="https://umiamihealth.org/en/treatments-and-services/urology"
  />
)

export default UMiamiLandingPage
